<template>
  <Content
    :current-id="currentId" 
    :init='{ dispatchAction: "RETURN", currentId: currentId }'
    :submenu='{ state: true, name: "returns", currentData: "currentReturn"}'
    :toolbar='{ init: true, name: "returns", dispatchAction: "RETURN", currentData: "currentReturn", currentId: currentId}' 
  />
</template>

<script>
import { defineComponent, ref, computed, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { useContentStore } from "@/stores/content";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import Content from '@/components/Content.vue';

export default defineComponent({
  components: {
    Content,
  },
  setup(props, {emit}) {
    const route = useRoute();
    const { t } = useI18n();
    const contentStore = useContentStore();

    const currentId = computed(() => {
      return route.params.uid
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      contentStore.setBreadcrumbTitle(t('titles.Return') + ' - ' + currentId.value);
    })

    return {
      currentId,
    }
  }
})
</script>
